import React from 'react'
import { css, Global } from '@emotion/core'

const GlobalStyles = ({}) => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Maison Bold';
        font-weight: 500;
        font-style: normal;
        src: url('assets/fonts/Maison Neue Bold.otf');
        font-display: swap;
      }

      @font-face {
        font-family: 'Maison';
        font-weight: 300;
        font-style: normal;
        src: url('assets/fonts/Maison Neue Medium.otf');
        font-display: swap;
      }

      html,
      body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
      }

      html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscaled;
        text-size-adjust: 100%;
        text-rendering: optimizeLegibility;
        font-size: 62.5%;
      }

      body {
        font-family: 'Maison', sans-serif;
        font-size: 1.6rem;
        color: #111517;
        line-height: 1.4;
        font-weight: 300;
      }

      b {
        font-family: 'Maison Bold', sans-serif;
        font-weight: 500;
      }
    `}
  />
)

export default GlobalStyles
