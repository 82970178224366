import styled from '@emotion/styled'

export const Wrap = styled.div`
  padding: 10rem 0;
`

export const Container = styled.div`
  display: flex;
  max-width: 100rem;
  margin: auto;
`

export const FormColumn = styled.div`
  width: 60%;
  margin: 0 5rem 0 0;
`

export const PreviewColumn = styled.div`
  width: 40%;
  max-width: 56.4rem;
`

export const Button = styled.button`
  background-color: #ef0d33;
  text-transform: uppercase;
  border: 0.2rem solid transparent;
  border-radius: 0.2rem;
  width: 100%;
  color: #fff;
  letter-spacing: 0.2rem;
  font-family: 'Maison Bold', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  padding: 2.4rem 0 2rem;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: #c30928;
  }
`
