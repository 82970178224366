import styled from '@emotion/styled'

export const Wrap = styled.div`
  margin: 0 0 3rem 0;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
  font-weight: 500;
  font-family: 'Maison Bold', sans-serif;
`

export const Description = styled.span`
  color: #666;
  font-size: 1.5rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 2rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
  font-weight: 300;
  font-family: 'Maison', sans-serif;
  color: #111517;

  ${({ hasError }) => (hasError ? 'border-color: red;' : null)}
`

export const Error = styled.span`
  color: red;
  font-size: 1.5rem;
  margin-left: auto;
`
