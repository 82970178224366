export const generateTemplate = (formData) => {
  return `
    <div
      style="font-size: 16px; color: #111517; font-family: sans-serif;"
    >
      <br />
      <b style="font-size: 16px;">${String(
        formData.fullname
      ).toLocaleUpperCase()}</b><br />
      <span style="color: #666;font-size: 16px;">${formData.position}</span>
      <br />
      ${formData.address && `<b>A:</b> ${formData.address}<br />`}
      ${
        formData.phoneNumber &&
        `
        <b>M:</b> 
        <a 
          href="tel:${formData.phoneNumber}"
          style="color: #EF0D33; font-size: 16px; text-decoration: underline;"
        >${formData.phoneNumber}</a>
        <br />
      `
      }
      <b>W:</b>
      <a
        href="https://www.strv.com?utm_source=email_signature&utm_medium=email_signature&utm_content=Website&utm_campaign=email_signature"
        style="color: #EF0D33; font-size: 16px;"
      >www.strv.com</a>      
      ${
        formData.meetingLink &&
        `
        <br />
        <a
          href=${formData.meetingLink}
          style="color: #EF0D33; font-size: 16px;"
        >Book a meeting</a>
        
      `
      }
      <br /><br />
      <a
        style="font-size: 0; text-decoration: none;"
        href="https://x.com/strvcom?utm_source=email_signature&utm_medium=email_signature&utm_content=Twitter&utm_campaign=email_signature"
      >
        <img
          alt="X"
          src="https://www.strv.com/email/x@2x.png"
          height="24"
          width="24"
        />
      </a>
      &nbsp;
      <a
        style="font-size: 0; text-decoration: none;"
        href="https://www.facebook.com/strvcom/?utm_source=email_signature&utm_medium=email_signature&utm_content=FB&utm_campaign=email_signature"
      >
        <img
          alt="Facebook"
          src="https://www.strv.com/email/fb@2x.png"
          height="24"
          width="24"
        />
      </a>
      &nbsp;
      <a
        style="font-size: 0; text-decoration: none;"
        href="https://www.linkedin.com/company/strvcom/?utm_source=email_signature&utm_medium=email_signature&utm_content=LinkedIn&utm_campaign=email_signature"
        ><img
          alt="LinkedIn"
          src="https://www.strv.com/email/linkedin@2x.png"
          height="24"
          width="24"
        />
      </a>
      &nbsp;
      <a
        style="font-size: 0; text-decoration: none;"
        href="https://www.instagram.com/strvcom/?utm_source=email_signature&utm_medium=email_signature&utm_content=IG&utm_campaign=email_signature"
        ><img
          alt="Instagram"
          src="https://www.strv.com/email/insta@2x.png"
          height="24"
          width="24"
        />
      </a>
      &nbsp;
      <a
        style="font-size: 0; text-decoration: none;"
        href="https://dribbble.com/strvcom?utm_source=email_signature&utm_medium=email_signature&utm_content=Dribbble&utm_campaign=email_signature"
        ><img
          alt="Dribbble"
          src="https://www.strv.com/email/dribbble@2x.png"
          height="24"
          width="24"
        />
      </a>

      <br />
      <br />

      <img
        alt="STRV"
        src="https://www.strv.com/email/strv@2x.png"
        width="64"
        height="33"
      />
    </div>
    `
}
