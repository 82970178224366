import React from 'react'
import { useField } from 'formik'
import * as Styled from './styled'

const Input = ({ label, optional, error, ...props }) => {
  const [field] = useField(props)

  if (props.error) {
    console.log(props)
  }

  return (
    <Styled.Wrap>
      <Styled.Label for={props.id || props.name}>
        {label}
        {optional && <Styled.Description> (optional)</Styled.Description>}
        {error ? <Styled.Error>{error}</Styled.Error> : null}
      </Styled.Label>
      <Styled.Input {...field} {...props} hasError={Boolean(error)} />
    </Styled.Wrap>
  )
}

export default Input
