import React from 'react'
import root from 'react-shadow'
import styled from '@emotion/styled'

import { generateTemplate } from '../../utils'

const Styled = {
  Window: styled.div`
    border-radius: 1rem;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.2);
  `,

  Header: styled.div`
    background: #f2f2f2;
    padding: 10px 16px;
    border-radius: 1rem 1rem 0 0;
    font-family: 'Google Sans', Roboto, RobotoDraft, Helvetica, Arial,
      sans-serif;
    font-size: 14px;
    font-weight: 500;
  `,

  Body: styled(root.div)`
    padding: 10px 16px;
  `,
}

const css = `
  :host {
    background: white;
    font: small/1.5 Arial,Helvetica,sans-serif;
  }
`

const Preview = ({ data }) => {
  const values = {
    fullname: data.fullname || 'Full Name',
    position: data.position || 'Job Title',
    phoneNumber: data.phoneNumber || '',
    address: data.address || '',
    meetingLink: data.meetingLink || '',
  }

  return (
    <Styled.Window>
      <Styled.Header>New message</Styled.Header>

      <Styled.Body>
        <style type="text/css">{css}</style>

        <p style={{ marginTop: 0 }}>Hello!</p>
        <p>This is an example of how your email signature will look like!</p>
        <p>Cheers!</p>
        <div
          dangerouslySetInnerHTML={{ __html: generateTemplate(values) }}
        ></div>
      </Styled.Body>
    </Styled.Window>
  )
}

export default Preview
