import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { generateTemplate } from './utils'
import Input from './components/input'
import Preview from './components/preview'
import GlobalStyles from './components/GlobalStyles'
import { Container, Button, Wrap, FormColumn, PreviewColumn } from './styled'

const initialValues = {
  fullname: '',
  position: '',
  phoneNumber: '',
  address: '',
  meetingLink: '',
}

const validation = yup
  .object()
  .required()
  .shape({
    fullname: yup.string().required('This field is required'),
    position: yup.string().required('This field is required'),
  })

const Index = () => {
  const handleSubmit = useCallback(async (values) => {
    let copied = false

    if (typeof ClipboardItem !== 'undefined' && 'clipboard' in navigator) {
      const type = 'text/html'
      const html = generateTemplate(values)
      const item = new ClipboardItem({ [type]: html })

      copied = await navigator.clipboard
        .write([item])
        .then(() => true)
        .catch((error) => (console.error(error), false))
    }

    if (!copied) {
      const copyListener = (event) => {
        const fixtureHtml = generateTemplate(values)
        event.clipboardData.setData('text/plain', fixtureHtml)
        event.clipboardData.setData('text/html', fixtureHtml)
        event.preventDefault()
        copied = true
      }

      document.addEventListener('copy', copyListener)
      document.execCommand('copy')
      document.removeEventListener('copy', copyListener)
    }

    copied
      ? alert('Copied!')
      : alert('Failed to copy: try another browser, please')
  }, [])

  return (
    <Wrap>
      <GlobalStyles />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validation}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <FormColumn>
                <Input
                  type="text"
                  name="fullname"
                  id="fullname"
                  label="Full Name"
                  error={errors.fullname}
                />
                <Input
                  type="text"
                  name="position"
                  id="position"
                  label="Job Title"
                  error={errors.position}
                />
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  label="Phone Number"
                  optional
                  pattern="[\d\s\(\)\+\-]+"
                  title="Phone number with digits,+,-,() only"
                />
                <Input
                  type="text"
                  name="address"
                  id="address"
                  label="Address"
                  optional
                />
                <Input
                  type="text"
                  name="meetingLink"
                  id="meetingLink"
                  label="Link to Meeting"
                  optional
                />
                <Button type="submit">Copy to clipboard</Button>
              </FormColumn>
              <PreviewColumn>
                <Preview data={values} />
              </PreviewColumn>
            </Container>
          </Form>
        )}
      </Formik>
    </Wrap>
  )
}

export default Index
